<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <h1>Extensive Ranching</h1>
      <p>
        As our concept is to mimic nature we look carefully how a natural environment for our farm animals can look like
        and provide it to them for their well-being. The animals can roam our land as a herd, a flock or as a pack. We
        use animal lanes that give them access to zones where they can graze and uproot the soil as per their natural
        instincts. These lanes also guide them towards water sources or places where supplemental feed is available.
      </p>
      <picture>
        <source media="(max-width: 500px)" srcset="../../assets/images/sm-2021-04-19_10-41-20.jpg">
        <img src="../../assets/images/2021-04-19_10-41-20.jpg">
      </picture>

      <p>
        Although EU regulation 2018/848 about ecologic production allows the use of ecologic feed indefinitely we follow
        the intention of said regulation and strive to feed the animals off the land they are living on. It will take
        time to achieve that goal as we are under threat of <router-link :to="{ name: 'desertification' }">
          desertification</router-link> but it is the very same animals who can help with that when managed well.
        See below what we feed the <a href="#feed_for_pigs">pigs</a> and <a href="#feed_for_cattle">cattle</a> when not
        enough grows on the land.
      </p>
      <picture>
        <source media="(max-width: 500px)" srcset="../../assets/images/sm-2022-05-18_13-18-34.jpg">
        <img src="../../assets/images/2022-05-18_13-18-34.jpg">
      </picture>

      <h2>Animal Products</h2>
      <p>
        The animals are not our primary product. We see them as part of the ecosystem we are aiming to restore and
        therefore their function within the system is a lot more valuable to us than a potential sale. For that reason
        we aim to keep each animal for as long as possible within the system.
      </p>
      <p>
        However, death is also part of any ecosystem. For example, in a herd of bovines the bulls will kill another herd
        member that is bleeding in order to protect the herd from predators. Animals also get sick, break a leg and
        there is no cure. In most cases the meat from such animals can be eaten by humans. It is not always necessary to
        actively kill healthy animals in order to obtain meat unless meat production and sales are the primary goal of
        the operation.
      </p>
      <p>
        We are working with the local authorities to be allowed to use a painless form of slaugther on the farm as per
        European Union regulations instead of trucking animals to a huge slaughterhouse hours away. This is a
        complicated process as it involves not only authorities from the agricultural department but also those working
        for public health. What further complicates this is the fact that the rules are made for huge industrial
        facilities.
      </p>
      <p>
        Given that we are under a threat of <router-link :to="{ name: 'desertification' }">desertification</router-link>
        we use animal impact to heal the land and make it more fertile as per the ways described under the umbrella of
        <em>Holistic Management</em> and the teachings of Alan Savory and others.
      </p>

      <YouTubeLink />

      <h2>External feed</h2>
      <p>
        When we don't have enough forage for the animals we buy feed from outside. You can see what is in the feed.
        There are some vitamins in the feed but there is no vitamin B12.
      </p>
      <h3 id="feed_for_pigs">External feed for Iberian pigs</h3>
      <picture>
        <source media="(max-width: 500px)" srcset="../../assets/images/sm-piensoCerdoIberico.jpg">
        <img width="100%" src="../../assets/images/piensoCerdoIberico.jpg">
      </picture>
      <h3 id="feed_for_cattle">External feed for cattle</h3>
      <picture>
        <source media="(max-width: 500px)" srcset="../../assets/images/sm-piensoBovinoExtensivo.jpg">
        <img width="100%" src="../../assets/images/piensoBovinoExtensivo.jpg">
      </picture>
    </div>
  </div>
</template>

<script>
import Sidebar from './Sidebar.vue'
import YouTubeLink from '@/components/YouTubeLink.vue'

export default {
  name: "AnimalsEN",
  components: {
    Sidebar,
    YouTubeLink
  },
};
</script>
